import { Icon, Menu, Tab, TabPane } from "semantic-ui-react"
import { BulkDataConfig } from "./BulkDataConfig"
import { DeviceJobConfigView } from "./DeviceJobConfigView"

export const DeviceConfigTab = ({ serialNumber }: { serialNumber: string }) => {
    return (
        <Tab
            menu={{ fluid: true, vertical: true }}
            grid={{ paneWidth: 14, tabWidth: 2 }}
            panes={[
                {
                    menuItem: (
                        <Menu.Item key="bulkDataConfig">
                            <Icon name="cloud upload" />
                            Bulk Data
                        </Menu.Item>
                    ),
                    render: () => (
                        <TabPane>
                            <BulkDataConfig serialNumber={serialNumber} />
                        </TabPane>
                    ),
                },
                {
                    menuItem: (
                        <Menu.Item key="deviceJobConfig">
                            <Icon name="compass outline" />
                            Smart Wi-Fi
                        </Menu.Item>
                    ),
                    render: () => (
                        <TabPane>
                            <DeviceJobConfigView serialNumber={serialNumber} />
                        </TabPane>
                    ),
                },
            ]}
        />
    )
}
