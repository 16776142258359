import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Statistic } from "semantic-ui-react"
import { getCpeStats, selectCpeStats } from "../store/slices/cpeStats"
import { AppDispatch } from "../store/store"
import { Center } from "./Layout"

export default function StatsView() {
    const dispatch = useDispatch<AppDispatch>()
    const cpeStats = useSelector(selectCpeStats)

    useEffect(() => {
        dispatch(getCpeStats())
    }, [])

    return (
        <Center>
            <Statistic.Group>
                <Statistic>
                    <Statistic.Value>{cpeStats.activeCount}</Statistic.Value>
                    <Statistic.Label>Active CPEs</Statistic.Label>
                </Statistic>
                <Statistic>
                    <Statistic.Value>{cpeStats.totalCount}</Statistic.Value>
                    <Statistic.Label>Total CPEs</Statistic.Label>
                </Statistic>
            </Statistic.Group>
        </Center>
    )
}
