import { jwtDecode } from "jwt-decode"

export interface Token {
    upn: string
    groups: string[]
}

export function decodeAccessToken(token: string): Token {
    return jwtDecode<Token>(token)
}
