import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Button,
    Dimmer,
    Dropdown,
    Header,
    Icon,
    Loader,
    Message,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from "semantic-ui-react"
import {
    ChannelProfile,
    DayOfWeekChannelProfiles,
    getChannelProfiles,
    selectChannelProfiles,
} from "../store/slices/channelProfilesSlice"
import { WifiBand } from "../store/slices/radioSlice"
import { AppDispatch } from "../store/store"
import { DayOfWeek } from "../store/types/dayOfWeek"
import { listToDropdown } from "../utils/componentHelpers"

const ALL_HOUR_OF_DAY = -1

const bandDropdownOptions = listToDropdown(["2.4GHz", "5GHz", "6GHz"])
const dayOfWeekDropdownOptions = listToDropdown(Object.values(DayOfWeek))
const hourOfDayDropdownOptions = [
    {
        key: "all",
        text: "All",
        value: ALL_HOUR_OF_DAY,
    },
].concat(
    [...Array(24)].map((_, i) => {
        return {
            key: i.toString(),
            text: formatHourIdx(i),
            value: i,
        }
    }),
)

function formatHourIdx(hour: number): string {
    return `${hour.toString().padStart(2, "0")}:00`
}

interface HourChannelProfile extends ChannelProfile {
    hourId: number
}

const compareChannelProfile = (a: HourChannelProfile, b: HourChannelProfile) => {
    if (a.hourId != b.hourId) {
        return a.hourId - b.hourId
    }
    if (a.channel != b.channel) {
        return a.channel - b.channel
    }
    return a.bandwidth - b.bandwidth
}

function filterChannelProfiles(
    channelProfiles: DayOfWeekChannelProfiles[],
    band: WifiBand,
    dayOfWeek: DayOfWeek,
    hour: number,
): HourChannelProfile[] {
    let dayOfWeekProfiles = channelProfiles.find((d) => d.dayOfWeek === dayOfWeek)
    let bandProfiles = dayOfWeekProfiles?.bandProfiles.find((bp) => bp.band === band)
    let filteredProfiles: HourChannelProfile[] = []
    if (hour === ALL_HOUR_OF_DAY) {
        filteredProfiles =
            bandProfiles?.hourlyProfiles.flatMap((hp) =>
                hp.channelProfiles.map((cp) => {
                    return {
                        ...cp,
                        hourId: hp.hourId,
                    }
                }),
            ) || []
    } else {
        filteredProfiles =
            bandProfiles?.hourlyProfiles
                .find((hp) => hp.hourId === hour)
                ?.channelProfiles.map((cp) => {
                    return {
                        hourId: hour,
                        ...cp,
                    }
                }) || []
    }
    return filteredProfiles.sort(compareChannelProfile)
}

export const ChannelProfilesView = ({ serialNumber }: { serialNumber: string }) => {
    const dispatch = useDispatch<AppDispatch>()
    const channelProfilesState = useSelector(selectChannelProfiles)
    const [band, setBand] = useState<WifiBand>("2.4GHz")
    const [dayOfWeek, setDayOfWeek] = useState<DayOfWeek>(DayOfWeek.Monday)
    const [hourOfDay, setHourOfDay] = useState<number>(ALL_HOUR_OF_DAY)
    const [filteredProfiles, setFilteredProfiles] = useState<HourChannelProfile[]>([])

    const refreshProfileData = () => {
        dispatch(getChannelProfiles({ serialNumber: serialNumber }))
    }

    useEffect(refreshProfileData, [serialNumber])

    useEffect(() => {
        if (channelProfilesState.profiles != null) {
            setFilteredProfiles(filterChannelProfiles(channelProfilesState.profiles, band, dayOfWeek, hourOfDay))
        }
    }, [channelProfilesState, band, dayOfWeek, hourOfDay])

    return (
        <>
            <Header>Channel Profiles</Header>
            <div style={{ display: "flex" }}>
                <Dropdown
                    selection
                    options={bandDropdownOptions}
                    value={band}
                    onChange={(_, data) => setBand(data.value as WifiBand)}
                />
                <Dropdown
                    selection
                    options={dayOfWeekDropdownOptions}
                    value={dayOfWeek}
                    onChange={(_, data) => setDayOfWeek(data.value as DayOfWeek)}
                />
                <Dropdown
                    selection
                    options={hourOfDayDropdownOptions}
                    value={hourOfDay}
                    onChange={(_, data) => setHourOfDay(data.value as number)}
                />
                <Button circular style={{ marginLeft: "auto" }} icon="refresh" onClick={refreshProfileData} />
            </div>
            <div>
                <Dimmer inverted active={channelProfilesState.loading}>
                    <Loader active={channelProfilesState.loading} content="Loading" />
                </Dimmer>
                {channelProfilesState.lastError != null ? (
                    <Message warning>
                        <Icon name="warning" />
                        {channelProfilesState.lastError}
                    </Message>
                ) : filteredProfiles.length !== 0 ? (
                    <Table celled>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>Hour</TableHeaderCell>
                                <TableHeaderCell>Channel</TableHeaderCell>
                                <TableHeaderCell>Bandwidth</TableHeaderCell>
                                <TableHeaderCell>Fitness</TableHeaderCell>
                                <TableHeaderCell>Observation Count</TableHeaderCell>
                                <TableHeaderCell>Last Observation Date</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredProfiles.map((profile) => (
                                <TableRow>
                                    <TableCell>{formatHourIdx(profile.hourId)}</TableCell>
                                    <TableCell>{profile.channel}</TableCell>
                                    <TableCell>{profile.bandwidth}</TableCell>
                                    <TableCell>{profile.fitnessValue}</TableCell>
                                    <TableCell>{profile.observationCount}</TableCell>
                                    <TableCell>{profile.lastObservationDate}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Message warning>
                        <Icon name="warning" />
                        {"No profile data found for selected time"}
                    </Message>
                )}
            </div>
        </>
    )
}
