import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { tryFetch } from "../../utils/api"
import { RootState } from "../store"

export interface CpeStats {
    totalCount: number
    activeCount: number
}

const createInitialState = (): CpeStats => {
    return {
        totalCount: 0,
        activeCount: 0,
    }
}

export const getCpeStats = createAsyncThunk("getCpeStats", async (_, { rejectWithValue }) => {
    let rsp = await tryFetch("/acs/stats", {
        method: "GET",
    })
    let body = await rsp.json()
    if (rsp.status >= 400) {
        return rejectWithValue(body)
    }
    return body as CpeStats
})

export const cpeStatsSlice = createSlice({
    name: "cpeStatsSlice",
    initialState: createInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCpeStats.pending, (state, action) => {})
        builder.addCase(getCpeStats.fulfilled, (state, action) => {
            return action.payload
        })
        builder.addCase(getCpeStats.rejected, (state, action) => {
            return createInitialState()
        })
    },
})

export const cpeStatsReducer = cpeStatsSlice.reducer
export const selectCpeStats = (state: RootState) => state.cpeStats
