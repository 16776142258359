import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { tryFetch } from "../../utils/api"
import { RootState } from "../store"

interface DeviceJobConfig {
    channelManagementEnabled: boolean
    explorationProbability: number | null
}

interface DeviceJobConfigState {
    serialNumber: string | null
    jobConfig: DeviceJobConfig | null
    loading: boolean
    lastError: string | null
}

function createInitialState(): DeviceJobConfigState {
    return {
        serialNumber: null,
        jobConfig: null,
        loading: false,
        lastError: null,
    }
}

interface GetDeviceJobConfigPayload {
    serialNumber: string
}

export const getDeviceJobConfig = createAsyncThunk(
    "getDeviceJobConfig",
    async (payload: GetDeviceJobConfigPayload, { rejectWithValue }) => {
        let rsp = await tryFetch(`/acs/cpes/${payload.serialNumber}/job-config`, {
            method: "GET",
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body as DeviceJobConfig
    },
)

export interface WithDefault<T> {
    value?: T
    useDefault?: boolean
}

export interface UpdateDeviceJobConfig {
    channelManagementEnabled?: boolean
    explorationProbability?: WithDefault<number>
}

interface UpdateDeviceJobConfigPayload {
    serialNumber: string
    data: UpdateDeviceJobConfig
}

export const updateDeviceJobConfig = createAsyncThunk(
    "updateDeviceJobConfig",
    async (payload: UpdateDeviceJobConfigPayload, { rejectWithValue }) => {
        let rsp = await tryFetch(`/acs/cpes/${payload.serialNumber}/job-config`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload.data),
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body as DeviceJobConfig
    },
)

export const deviceJobConfigSlice = createSlice({
    name: "deviceJobConfig",
    initialState: createInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDeviceJobConfig.pending, (state, action) => {
            const serialNumber = action.meta.arg.serialNumber
            if (serialNumber !== state.serialNumber) {
                return {
                    ...createInitialState(),
                    serialNumber: serialNumber,
                    loading: true,
                }
            }
            state.loading = true
        })
        builder.addCase(getDeviceJobConfig.fulfilled, (state, action) => {
            if (state.serialNumber !== action.meta.arg.serialNumber) {
                return
            }
            state.jobConfig = action.payload
            state.loading = false
            state.lastError = null
        })
        builder.addCase(getDeviceJobConfig.rejected, (state, action) => {
            if (state.serialNumber !== action.meta.arg.serialNumber) {
                return
            }
            state.loading = false
            state.lastError = "Failed to get device job configuration"
        })
        builder.addCase(updateDeviceJobConfig.pending, (state, action) => {
            const serialNumber = action.meta.arg.serialNumber
            if (serialNumber !== state.serialNumber) {
                state.lastError = "Serial number mismatch"
                return
            }
            state.loading = true
            state.lastError = null
        })
        builder.addCase(updateDeviceJobConfig.fulfilled, (state, action) => {
            if (state.serialNumber !== action.meta.arg.serialNumber) {
                return
            }
            state.jobConfig = action.payload
            state.loading = false
            state.lastError = null
        })
        builder.addCase(updateDeviceJobConfig.rejected, (state, action) => {
            if (state.serialNumber !== action.meta.arg.serialNumber) {
                return
            }
            state.loading = false
            state.lastError = "Failed to update device job configuration"
        })
    },
})

export const deviceJobConfigReducer = deviceJobConfigSlice.reducer

export const selectDeviceJobConfig = (state: RootState) => state.deviceJobConfig
