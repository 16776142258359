import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { API_BASE } from "../../config"
import { RootState } from "../store"

export interface AuthTokens {
    access: string
    refresh: string
}

export enum AuthStatus {
    LoggedOut = "LOGGED_OUT",
    LoggingIn = "LOGGING_IN",
    LoggedIn = "LOGGED_IN",
}

interface AuthState {
    status: AuthStatus
    loginError: string | null
    tokens: AuthTokens | null
}

interface LoginPayload {
    username: string
    password: string
}

interface LogoutPayload {
    message: string | undefined
}

const createInitialState = (): AuthState => {
    const accessToken = localStorage.getItem("access")
    const refreshToken = localStorage.getItem("refresh")
    const loggedIn = accessToken !== null && refreshToken !== null
    return {
        status: loggedIn ? AuthStatus.LoggedIn : AuthStatus.LoggedOut,
        loginError: null,
        tokens: loggedIn
            ? {
                  access: accessToken,
                  refresh: refreshToken,
              }
            : null,
    }
}

export const login = createAsyncThunk("auth/login", async (loginInfo: LoginPayload, { dispatch, rejectWithValue }) => {
    let rsp = await fetch(`${API_BASE}/auth/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(loginInfo),
    })

    if (rsp.status !== 200) {
        return rejectWithValue(await rsp.json())
    }

    let body = await rsp.json()
    localStorage.setItem("access", body.access)
    localStorage.setItem("refresh", body.refresh)
    return body as AuthTokens
})

export const authSlice = createSlice({
    name: "auth",
    initialState: createInitialState(),
    reducers: {
        logout: (state, action: PayloadAction<LogoutPayload | undefined>) => {
            localStorage.removeItem("access")
            localStorage.removeItem("refresh")
            state.tokens = null
            state.status = AuthStatus.LoggedOut
            state.loginError = action?.payload?.message || null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state, action: any) => {
            state.status = AuthStatus.LoggingIn
        })
        builder.addCase(login.fulfilled, (state, action) => {
            state.loginError = null
            state.status = AuthStatus.LoggedIn
            state.tokens = action.payload
        })
        builder.addCase(login.rejected, (state, action: any) => {
            console.log(JSON.stringify(action.payload))
            state.status = AuthStatus.LoggedOut
            state.loginError = action?.payload?.message || "Login failed"
        })
    },
})

export const { logout } = authSlice.actions

export const authReducer = authSlice.reducer

export const selectAuthState = (state: RootState) => state.auth
export const selectAuthStatus = (state: RootState) => state.auth.status
