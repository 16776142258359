import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { v4 as uuidv4 } from "uuid"
import { tryFetch } from "../../utils/api"
import { RootState } from "../store"

export enum CommandType {
    Get = "GET",
    Set = "SET",
    Add = "ADD",
    Delete = "DELETE",
    GetSupportedDM = "GET_SUPPORTED_DM",
}

export enum ResultType {
    Get = "GET",
    Set = "SET",
    Add = "ADD",
    Delete = "DELETE",
    GetSupportedDM = "GET_SUPPORTED_DM",
    Error = "ERROR",
}

export interface GetCommand {
    paths: string[]
}

export interface RequestedPath {
    resolvedPaths: Record<string, Record<string, string>>
    errorMessage: string
    errorCode: number
}

export interface GetCommandResult {
    requestedPaths: Record<string, RequestedPath>
}

export const flattenGetResults = (result: GetCommandResult): Record<string, Record<string, string>> => {
    let resolvedPaths: Record<string, Record<string, string>> = {}
    for (const requestedPathResult of Object.values(result.requestedPaths)) {
        for (const [resolvedPath, params] of Object.entries(requestedPathResult.resolvedPaths))
            resolvedPaths[resolvedPath] = {
                ...resolvedPaths[resolvedPath],
                ...params,
            }
    }
    return resolvedPaths
}

export interface SetCommand {
    paths: Record<string, Record<string, string>>
}

export interface SetCommandResult {
    success: Record<string, string>
    errors: Record<string, string>
}

export interface AddCommand {
    createObjects: Array<{
        path: string
        params: Record<string, string>
    }>
}

export interface AddCommandResult {
    created: Array<{
        requestedPath: string
        createdPath: string
        uniqueKeys: Record<string, string>
        errors: Array<PathErrorResult>
    }>
    errors: Record<string, string>
}

export interface DeleteCommand {
    paths: Array<string>
}

export interface DeleteCommandResult {
    affectedPaths: Array<string>
    errors: Array<PathErrorResult>
}

export interface GetSupportedDMCommand {
    objPaths: Array<string>
}

export interface SupportedParam {
    name: string
    access: string
    valueType: string
    valueChange: string
}

export interface SupportedCommand {
    name: string
    commandType: string
    inputArgs: Array<string>
    outputArgs: Array<string>
}

export interface SupportedEvent {
    name: string
    args: Array<string>
}

export interface SupportedObject {
    path: string
    access: string
    isMultiInstance: boolean
    supportedParams: Array<SupportedParam>
    supportedCommands: Array<SupportedCommand>
    supportedEvents: Array<SupportedEvent>
}

export interface GetSupportedDMCommandResult {
    supportedObjs: Array<SupportedObject>
}

export interface ErrorCommandResult {
    code: number
    message: string
}

export interface PathErrorResult {
    path: string
    code: number
    message: string
}

type CommandData = GetCommand | SetCommand | AddCommand | DeleteCommand | GetSupportedDMCommand
type CommandResultData =
    | GetCommandResult
    | SetCommandResult
    | AddCommandResult
    | GetSupportedDMCommandResult
    | DeleteCommandResult
    | ErrorCommandResult

export interface Command {
    id?: string
    type: CommandType
    data: CommandData
}

export interface CommandResult {
    type: ResultType
    data: CommandResultData
}

interface CpeUpdatePayload {
    id: string
    serialNumber: string
    command: Command
}

export interface EnqueuedCommand {
    id: string
    request: Command
    response: CommandResult | null
    status: string
    createdAt: string
    updatedAt: string
}

export type DataModel = { [Key: string]: DataModel | string }

export const getPathOnDM = (
    dm: Record<string, Record<string, string>>,
    objPath: string,
    paramName: string,
): string | null => {
    let obj = dm[objPath]
    if (obj === undefined) return null

    return obj[paramName]
}

interface CommandsState {
    commands: Record<string, EnqueuedCommand>
    hasEnqueued: boolean
    dataModel: Record<string, Record<string, string>>
    supportedDM: Record<string, SupportedObject>
    currentObjectForInfo: SupportedObject | null
}

const createInitialState = (): CommandsState => {
    return {
        commands: {},
        hasEnqueued: false,
        dataModel: {},
        supportedDM: {},
        currentObjectForInfo: null,
    }
}

const getCommandUrl = (command: Command) => {
    switch (command.type) {
        case CommandType.Get:
            return "get"
        case CommandType.Set:
            return "set"
        case CommandType.Add:
            return "add"
        case CommandType.Delete:
            return "delete"
        case CommandType.GetSupportedDM:
            return "get-supported-dm"
    }
}

const getResultType = (type: CommandType) => {
    switch (type) {
        case CommandType.Get:
            return ResultType.Get
        case CommandType.Set:
            return ResultType.Set
        case CommandType.Add:
            return ResultType.Add
        case CommandType.Delete:
            return ResultType.Delete
        case CommandType.GetSupportedDM:
            return ResultType.GetSupportedDM
    }
}

export const sendCommand = createAsyncThunk<any, CpeUpdatePayload, { state: RootState }>(
    "commands/send",
    async (updateData: CpeUpdatePayload, { getState, rejectWithValue, dispatch }) => {
        let commandPath = getCommandUrl(updateData.command)
        let rsp = await tryFetch(`/acs/cpes/${updateData.serialNumber}/${commandPath}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(updateData.command.data),
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return {
            response: {
                type: getResultType(updateData.command.type),
                data: body,
            },
            status: "done",
            updatedAt: new Date().toISOString(),
        }
    },
)

export function createCommandAction(serialNumber: string, type: CommandType, data: CommandData) {
    return sendCommand({
        id: uuidv4(),
        serialNumber: serialNumber,
        command: {
            type: type,
            data: data,
        },
    })
}

export const commandSlice = createSlice({
    name: "commandSlice",
    initialState: createInitialState(),
    reducers: {
        clear: (_state) => createInitialState(),
        setObjectForInfo: (state, action: PayloadAction<SupportedObject | null>) => {
            state.currentObjectForInfo = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(sendCommand.pending, (state, action: any) => {
            state.hasEnqueued = true
            const payload = action.meta.arg
            const now = new Date().toISOString()
            state.commands[payload.id] = {
                id: payload.id,
                request: payload.command,
                response: null,
                status: "enqueued",
                createdAt: now,
                updatedAt: now,
            }
        })
        builder.addCase(sendCommand.fulfilled, (state, action) => {
            state.hasEnqueued = false
            const response = action.payload.response
            const command = action.meta.arg
            const commandId = command.id

            if (state.commands[commandId] !== undefined) {
                state.commands[commandId] = {
                    ...state.commands[commandId],
                    ...action.payload,
                }
                if (response.type === ResultType.Get) {
                    let dataModel = state.dataModel
                    let resolvedPaths = flattenGetResults(response.data as GetCommandResult)
                    for (const [path, params] of Object.entries(resolvedPaths)) {
                        let obj = dataModel[path]
                        if (obj === undefined) {
                            dataModel[path] = params
                        } else {
                            dataModel[path] = {
                                ...obj,
                                ...params,
                            }
                        }
                    }
                } else if (response.type === ResultType.GetSupportedDM) {
                    let supportedDM = state.supportedDM
                    let supportedObjs = response.data.supportedObjs
                    for (const supportedObj of supportedObjs) {
                        supportedDM[supportedObj.path] = supportedObj
                    }
                    state.supportedDM = supportedDM
                } else if (response.type === ResultType.Add) {
                    let dataModel = state.dataModel
                    let createdObjs = (response.data as AddCommandResult).created
                    for (const createdObj of createdObjs) {
                        dataModel[createdObj.createdPath] = createdObj.uniqueKeys
                    }
                } else if (response.type === ResultType.Delete) {
                    let dataModel = state.dataModel
                    let affectedPaths = response.data.affectedPaths
                    state.dataModel = Object.keys(state.dataModel)
                        .filter((key) => !affectedPaths.some((path: string) => key.startsWith(path)))
                        .reduce((res: Record<string, Record<string, string>>, key: string) => {
                            res[key] = dataModel[key]
                            return res
                        }, {})
                }
            }
        })
        builder.addCase(sendCommand.rejected, (state, action: any) => {
            state.hasEnqueued = false
            const response = action.payload?.response
            const commandId = action.meta.arg.id

            if (state.commands[commandId] !== undefined) {
                state.commands[commandId] = {
                    ...state.commands[commandId],
                    response: response,
                    status: "error",
                    updatedAt: new Date().toISOString(),
                }
            }
        })
    },
})

export const commandReducer = commandSlice.reducer
export const { clear, setObjectForInfo } = commandSlice.actions

export const selectCommands = (state: RootState) => state.commands.commands
export const selectHasEnqueued = (state: RootState) => state.commands.hasEnqueued
export const selectDataModel = (state: RootState) => state.commands.dataModel
export const selectSupportedDM = (state: RootState) => state.commands.supportedDM
export const selectCurrentObjectForInfo = (state: RootState) => state.commands.currentObjectForInfo
